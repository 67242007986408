/**
 * @fileoverview Contiene los servicios de consumo a la API para tarifas
 *
 * @version 1.0
 *
 * @author Raúl Ariel Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por Raúl Ariel Rodríguez Barceló
 */

import apiRate from '@/services/api'
const END_POINT = '/tarifas'

export default {
  /**
   * @name getRates
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getRates(params) {
    if (params != null) {
      return apiRate.get(END_POINT + params)
    }
    return apiRate.get(END_POINT)
  },

  /**
   * @name postRate
   * @description Crea un nuevo documento
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postRate(body) {
    return apiRate.post(END_POINT, body)
  },

  /**
   * @name postRateTransfer
   * @description Obtiene las tarifas de un traslado
   *
   * @param {*} body Input del diálogo -> Propiedades para obtener las tarifas
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postRatesTransfer(body) {
    return apiRate.post(END_POINT + '/traslado', body)
  },

  /**
   * @name patchRate
   * @description Actualiza un documento por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchRate(body, id) {
    return apiRate.patch('/tarifas/' + id, body)
  },

  /**
   * @name deleteRate
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  deleteRate(id) {
    return apiRate.delete('/tarifas/' + id)
  },
}
