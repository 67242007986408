/**
 * @fileoverview Contiene los servicios de consumo a la API para Modelo de Vehiculos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import api from '@/services/api'
const END_POINT = '/servicios'
const END_POINT_AUX = '/servicios/categorias'
/* import axios from 'axios'
const baseURL = process.env.VUE_APP_BASE_URL_API

//Axios para Datos
const apiService = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
}) */
export default {
  /**
   * @name getService
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getService(params) {
    /* apiService.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    if (params != null) {
      return apiService.get('/servicios/' + params)
    }
    return apiService.get('/servicios') */
    if (params != null) {
      return api.get(END_POINT + params)
    }

    return api.get(END_POINT)
  },

  //Obtiene todas las categorias de servicios
  /**
   * @name getServiceCategory
   * @description Obtiene un listado de categorias de servicios segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getServiceCategory(params) {
    /*     apiService.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    if (params != null) {
      return apiService.get('/servicios/categorias' + params)
    }
    return apiService.get('/servicios/categorias')
    
 */
    if (params != null) {
      return api.get(END_POINT_AUX + params)
    }

    return api.get(END_POINT_AUX)
  },

  /**
   * @name postService
   * @description Se crea el servicio
   *
   * @param {*} jwt JSON Web Token válido
   * @param {*} body Input del formulario -> Propiedades para crear el servicio
   */
  postService(body) {
    //apiService.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    //return apiService.post('/servicios', body)
    return api.post(END_POINT, body)
  },

  //Actualiza un servicio
  /**
   * @name patchService
   * @description Se actualiza el servicio
   *
   * @param {*} jwt JSON Web Token válido
   * @param {*} body Input del formulario -> Propiedades para actualizar el servicio
   * @param {*} id this.editedItem._id -> id del servicio que se va a actualizar
   */
  patchService(body, id) {
    //apiService.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    //return apiService.patch('/servicios/' + id, body)
    return api.patch(END_POINT + '/' + id, body)
  },

  //Borra un servicio
  /**
   * @name deleteLanguaje
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteImageService(body, id) {
    //apiService.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    //return apiService.delete('/servicios/' + id, body)
    return api.delete(END_POINT + '/' + id)
  },
}
