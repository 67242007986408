/**
 * @fileoverview Contiene los servicios de consumo a la API para de transportadoras
 *
 * @version 1.0
 *
 * @author Omar Alberto Quijano Carballo
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por Omar Alberto Quijano Carballo
 */

import api from '@/services/api'
const END_POINT = '/transportadoras'

export default {
  /**
   * @name getCarriers
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} jwt JSON WEB Token válido
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getCarriers(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postCarrier
   * @description Crea un nuevo documento
   * @param {*} jwt JSON WEB Token válido
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postCarrier(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchCarrier
   * @description Actualiza un documento por su id
   *
   * @param {*} jwt JSON WEB Token válido
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchCarrier(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },
}
