/**
 * @fileoverview Contiene el archivo del store de Categorías de Productos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import ProductCategoryService from './productCategory.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchProductCategory
   * @description Obtiene una lista de Categorias de Productos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchProductCategory(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ProductCategoryService.getProductCategories(params)
  },

  /**
   * @name addProductCategory
   * @description Crea una nueva Categoria de Productos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear una nueva Categoria de Productos
   */
  addProductCategory(context, payload) {
    return ProductCategoryService.postProductCategory(payload.body)
  },

  /**
   * @name editProductCategory
   * @description Edita una nueva Categoria de Productos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Categoria de Productos
   */
  editProductCategory(context, payload) {
    return ProductCategoryService.patchProductCategory(payload.body, payload.id)
  },

  /**
   * @name deleteProductCategory
   * @description Elimina una Categoria de Productos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Categoria de Productos
   */
  deleteProductCategory(context, payload) {
    return ProductCategoryService.deleteProductCategory(payload.body, payload.id)
  },
}
