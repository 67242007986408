/**
 * @fileoverview Contiene los servicios de consumo a la API para Modelo de Hoteles
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/hoteles'

export default {
  /**
   * @name getHotels
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getHotels(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postHotel
   * @description Se crea el Hotel
   *
   * @param {*} jwt JSON Web Token válido
   * @param {*} body Input del formulario -> Propiedades para crear el Hotel
   */
  postHotel(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchHotel
   * @description Se actualiza el Hotel
   *
   * @param {*} jwt JSON Web Token válido
   * @param {*} body Input del formulario -> Propiedades para actualizar el Hotel
   * @param {*} id this.editedItem._id -> id del Hotel que se va a actualizar
   */
  patchHotel(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteHotel
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteHotel(id) {
    return api.delete(END_POINT + '/' + id)
  },
}
