/**
 * @fileoverview Contiene los servicios de consumo a la API para productos
 *
 * @version 1.0
 *
 * @author Raúl Ariel Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por Raúl Ariel Rodríguez Barceló
 */

import apiProduct from '@/services/api'
const END_POINT = '/productos'

export default {
  /**
   * @name getProduct
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getProduct(params) {
    if (params != null) {
      return apiProduct.get(END_POINT + params)
    }
    return apiProduct.get(END_POINT)
  },

  /**
   * @name postProduct
   * @description Crea un nuevo documento
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postProduct(body) {
    return apiProduct.post(END_POINT, body)
  },

  /**
   * @name patchProduct
   * @description Actualiza un documento por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchProduct(body, id) {
    return apiProduct.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteProduct
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  deleteProduct(id) {
    return apiProduct.delete(END_POINT + '/' + id)
  },

  /**
   * @name postImageProduct
   * @description Carga una imagen en el servidor
   *
   * @param {*} body Archivo a cargar en el servidor
   * @returns {json} json con el nombren de la imagen cargada
   */
  postImageProduct(body) {
    return apiProduct.post(END_POINT + '/imagen/upload', body)
  },
}
