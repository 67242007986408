/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de flotillas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import FleetService from './fleets.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchFleets
   * @description Obtiene una lista de vehiculos de la flotilla
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchFleets(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return FleetService.getFleets(params)
  },

  /**
   * @name addFleet
   * @description Crea un nuevo vehiculo de la flotilla
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Vehiculo
   */
  addFleet(context, payload) {
    return FleetService.postFleet(payload.body)
  },

  /**
   * @name editFleet
   * @description Edita un Vehiculo de la flotilla
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Vehiculo existente
   */
  editFleet(context, payload) {
    return FleetService.patchFleet(payload.body, payload.id)
  },

  /**
   * @name deleteFleet
   * @description Elimina un Vehiculo de la flotilla
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Vehiculo
   */
  deleteFleet(context, payload) {
    return FleetService.deleteFleet(payload.id)
  },
}
