/**
 * @fileoverview Contiene los servicios de consumo a la API para categorías
 * de productos
 *
 * @version 1.0
 *
 * @author José AntonioRodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José AntonioRodríguez Barceló
 */

import apiProductCategory from '@/services/api'
const END_POINT = '/productos/categorias'

export default {
  /**
   * @name getProductCategories
   * @description Obtiene una lista de Categorias de Productos
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   */
  getProductCategories(params) {
    if (params != null) {
      return apiProductCategory.get(END_POINT + '/' + params)
    }
    return apiProductCategory.get(END_POINT)
  },

  /**
   * @name postProductCategory
   * @description Se crea la Categoria de Producto
   *
   * @param {*} body Input del formulario -> Propiedades para crear la Categoria de Productos
   */
  postProductCategory(body) {
    return apiProductCategory.post(END_POINT, body)
  },

  /**
   * @name patchProductCategory
   * @description Se actualiza la Categoria de Productos
   *
   * @param {*} body Input del formulario -> Propiedades para actualizar la Categoria de Productos
   * @param {*} id this.editedItem._id -> id de la Categoria de Productos que se va a actualizar
   */
  patchProductCategory(body, id) {
    return apiProductCategory.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteProductCategory
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  deleteProductCategory(body, id) {
    return apiProductCategory.delete(END_POINT + '/' + id)
  },
}
