/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de aeropuertos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import Airport from './aeropuerto.service'
export const namespaced = true

export const state = {
  airports: [],
}

export const actions = {
  /**
   * @name fetchFerryTicket
   * @description Obtiene una lista de Boletos de Ferry
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchAirports(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return Airport.getAirports(params)
  },

  async getAirports({ commit }) {
    try {
      const response = await Airport.getAirports() // Asumiendo que esto devuelve una promesa
      const airports = response.data.map(({ aeropuerto, iata }) => ({
        value: iata,
        text: `${iata} - ${aeropuerto}`,
      }))
      commit('SET_AIRPORTS', airports)
    } catch (error) {
      console.error('Error al obtener los aeropuertos:', error)
    }
  },
}

export const mutations = {
  SET_AIRPORTS(state, airports) {
    state.airports = airports
  },
}
