/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de tipos de cambio
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import ExchangeRate from './ExchangeRates.service'
export const namespaced = true

export const actions = {
  /**
   * @name fetchExchangeRate
   * @description Obtiene una lista de Tipos de Cambio Divisas
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchExchangeRate(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ExchangeRate.getExchangeRates(params)
  },

  /**
   * @name addExchangeRate
   * @description Crea un nuevo Tipo de Cambio Divisas
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Tipo de Cambio Divisas
   */
  addExchangeRate(context, payload) {
    return ExchangeRate.postExchangeRate(payload.body)
  },

  /**
   * @name editExchangeRate
   * @description Edita un Tipo de Cambio Divisas
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un nuevo Tipo de Cambio Divisas
   */
  editExchangeRate(context, payload) {
    return ExchangeRate.patchExchangeRate(payload.body, payload.id)
  },

  /**
   * @name deleteExchangeRate
   * @description Elimina un Tipo de Cambio Divisas
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un tipo de cambio
   */
  deleteExchangeRate(context, payload) {
    return ExchangeRate.deleteExchangeRate(payload.id)
  },
}
