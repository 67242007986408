/**
 * @fileoverview Contiene los servicios de consumo a la API para Clientes
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/clientes'

export default {
  /**
   * @name getClient
   * @description Obtienen una lista de Marcas de Vehiculos
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getClient(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postClient
   * @description Se crea un Cliente
   *
   * @param {*} body Input del formulario -> Propiedades para crear un Cliente
   *
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postClient(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name getByQuery
   * @description Se crea un Cliente
   *
   * @param {*} body Input del formulario -> Propiedades para crear un Cliente
   *
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  getByQuery(body) {
    return api.post(END_POINT + '/info', body)
  },

  /**
   * @name patchClient
   * @description Se actualiza un Cliente
   *
   * @param {*} body Input del formulario -> Propiedades para actualizar el Cliente
   * @param {*} id this.editedItem._id -> id del Cliente que se va a actualizar
   *
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchClient(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteClient
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   *
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteClient(id) {
    return api.delete(END_POINT + '/' + id)
  },
}
