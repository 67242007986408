/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de géneros
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import GenderService from './gender.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchGenders
   * @description Obtiene una lista de Generos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchGenders(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return GenderService.getGenders(params)
  },

  /**
   * @name addGender
   * @description Crea un nuevo Genero
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Genero
   */
  addGender(context, payload) {
    return GenderService.postGender(payload.body)
  },

  /**
   * @name editGender
   * @description Edita un Genero
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Genero existente
   */
  editGender(context, payload) {
    return GenderService.patchGender(payload.body, payload.id)
  },

  /**
   * @name deleteGender
   * @description Elimina un Genero
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Genero
   */
  deleteGender(context, payload) {
    return GenderService.deleteGender(payload.id)
  },
}
