/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de tipos de vehiculos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import VehicleTypeService from './vehicleTypes.services'
export const namespaced = true

export const actions = {
  fetchVehicleTypes(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return VehicleTypeService.getVehicleTypes(params)
  },
  addVehicleType(context, payload) {
    return VehicleTypeService.postVehicleType(payload.body)
  },
  editVehicleType(context, payload) {
    return VehicleTypeService.patchVehicleType(payload.body, payload.id)
  },

  uploadVehicleImage(context, payload) {
    return VehicleTypeService.postImageVehicle(payload.body)
  },
}
