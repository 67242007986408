/**
 * @fileoverview Contiene el archivo del store de Categorías de Extras
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/servicios/categorias'

export default {
  /**
   * @name fetchextraCategory
   * @description Obtiene una lista de Categorias de Servicios Adicionales
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  getExtraCategories(params) {
    if (params != null) {
      return api.get(END_POINT + '/' + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postExtraCategory
   * @description Crea un nuevo documento
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postExtraCategory(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchExtraCategory
   * @description Actualiza un documento por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchExtraCategory(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteExtraCategory
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  deleteExtraCategory(body, id) {
    return api.delete(END_POINT + '/' + id)
  },
}
