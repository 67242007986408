/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de paises
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import CountryService from './country.service'
export const namespaced = true

export const actions = {
  /**
   * @name fetchCountries
   * @description Obtiene una lista de Paises
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchCountries(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return CountryService.getCountries(params)
  },

  /**
   * @name addCountry
   * @description Crea un nuevo Pais
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Pais
   */
  addCountry(context, payload) {
    return CountryService.postCountry(payload.body)
  },

  /**
   * @name editCountry
   * @description Edita un pais
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Pais
   */
  editCountry(context, payload) {
    return CountryService.patchCountry(payload.body, payload.id)
  },

  /**
   * @name deleteCountry
   * @description Elimina un pais
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Pais
   */
  deleteCountry(context, payload) {
    return CountryService.deleteCountry(payload.id)
  },
}
