/**
 * @fileoverview Contiene el enrutamiento a los diferentes Modulos del sistema
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    children: [
      {
        name: 'Dashboard',
        path: '',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/components/dashboard/Dashboard'),
      },
      {
        name: 'Circuitos',
        path: 'ventas/circuitos',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/views/dashboard/Ventas/Circuits'),
      },
      {
        name: 'Paseos',
        path: 'ventas/paseos',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/views/dashboard/Ventas/Tours'),
      },
      {
        name: 'Reservas',
        path: 'ventas/reservas',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/components/ventas/modulos/reservas/TrasladosReserva'),
      },
      {
        name: 'Traslados',
        path: 'ventas/traslados',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/components/ventas/modulos/traslados/Traslados'),
      },
      {
        name: 'Operaciones',
        path: 'ventas/operaciones',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/components/ventas/modulos/operaciones/Operaciones'),
      },
      {
        name: 'Cupones',
        path: 'ventas/cupones',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/components/ventas/modulos/cupones/Cupones'),
      },
      {
        name: 'Ajustes de Inventario',
        path: 'store/ajustes',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () =>
          import('@/components/almacen/modulos/ajustes_inventario/AjustesInventario'),
      },
      {
        name: 'Consola',
        path: 'store/console',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/views/dashboard/console/Console'),
      },
      {
        name: 'Perfil de Usuario',
        path: 'pages/user',
        meta: {
          requiresAuth: true,
          requiresAdmin: false,
        },
        component: () => import('@/views/dashboard/pages/UserProfile'),
      },
    ],
  },
  {
    path: '/admon',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    component: () => import('@/views/settings/Index'),
    children: [
      //Catálogo de clientes
      {
        name: 'Clientes',
        path: 'clientes',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/clientes/Clientes.vue'),
      },
      // Catálogo de flotillas
      {
        name: 'Flotilla',
        path: 'flotillas',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/flotillas/Flotillas.vue'),
      },
      {
        name: 'Géneros',
        path: 'generos',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/generos/Generos.vue'),
      },
      {
        name: 'Grupos',
        path: 'grupos',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/views/settings/usuarios/Grupos'),
      },
      {
        name: 'Hoteles',
        path: 'hoteles',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/hoteles/Hoteles'),
      },
      {
        name: 'Idiomas',
        path: 'idiomas',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/idiomas/Idiomas'),
      },
      {
        name: 'Módulos',
        path: 'modulos',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/configuration/modules/Modulos.vue'),
      },
      {
        name: 'Países',
        path: 'paises',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/paises/Paises'),
      },
      {
        name: 'Productos',
        path: 'productos',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/almacen/catalogos/productos/Productos'),
      },
      {
        name: 'Sistemas',
        path: 'sistemas',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/configuration/systems/Sistemas.vue'),
      },
      {
        name: 'Catálogo de Tarifas',
        path: 'tarifas',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/tarifas/Tarifas'),
      },
      {
        name: 'Traducciones',
        path: 'locales',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/configuration/locales/Locales'),
      },
      {
        name: 'Tipos Traslados',
        path: 'traslados',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/traslados/Traslados'),
      },
      {
        name: 'Usuarios',
        path: 'usuarios',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/configuration/users/Usuarios.vue'),
      },
      {
        name: 'Transportadoras',
        path: 'trasnportadoras',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/transportadoras/Transportadoras'),
      },
      {
        name: 'Zonas',
        path: 'zonas',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/zonas/Zonas'),
      },

      {
        name: 'Administración de Permisos para módulos',
        path: 'permisos',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/views/settings/usuarios/Permisos'),
      },

      {
        name: 'Catálogo de Tipos de Cambio',
        path: 'exchangeRate',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/tipo_cambio/TiposCambioDivisa'),
      },
      {
        name: 'Catálogo de Tipos de Vehiculo',
        path: 'tipos',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/tipo_vehiculos/TiposVehiculos'),
      },

      {
        name: 'Catálogo de Marcas',
        path: 'marcas',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/marca_vehiculo/MarcasVehiculo'),
      },
      {
        name: 'Catálogo de Modelos',
        path: 'modelos',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/modelo_vehiculo/ModelosVehiculo'),
      },

      {
        name: 'Catálogo de categorías de Poroductos',
        path: 'productoscat',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () =>
          import('@/components/almacen/catalogos/categoria_productos/CategoriasProducto'),
      },
      {
        name: 'Extras',
        path: 'extras',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/almacen/catalogos/extras/Extras'),
      },
      {
        name: 'Boletos Ferry',
        path: 'boletosferry',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/boletos/BoletosFerry'),
      },
      {
        name: 'Catálogo de categorías de Extras',
        path: 'servicioscat',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/almacen/catalogos/categoria_extras/CategoriasExtra'),
      },
      {
        name: 'Catálogo de Servicios',
        path: 'servicios',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/components/ventas/catalogos/servicios/Servicios'),
      },
      {
        name: 'Roles Usuario',
        path: 'roles',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import('@/views/settings/usuarios/RolesUsuario'),
      },
    ],
  },
  {
    path: '/pages',
    component: () => import('@/views/pages/Index'),
    children: [
      {
        name: 'Lock',
        path: 'lock',
        meta: {
          requiresAuth: false,
          requiresAdmin: false,
        },
        component: () => import('@/views/pages/Lock'),
      },
      {
        name: 'Login',
        path: 'login',
        meta: {
          requiresAuth: false,
          requiresAdmin: false,
        },
        component: () => import('@/views/pages/Login'),
      },
      {
        name: 'Register',
        path: 'register',
        meta: {
          requiresAuth: false,
          requiresAdmin: false,
        },
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/views/pages/Index'),
    children: [
      {
        name: '404 Error',
        path: '',
        component: () => import('@/views/pages/Error'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

//  Verifica que el usuario este logeado para cada una
//  de las rutas que requieren que este autenticado, en caso de no
//  estar envia a la pagina de inicio de sesión
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')
  let isAdmin = false

  if (store.state.auth.user.roles !== undefined) {
    isAdmin = store.state.auth.user.roles.includes('Administrador') ? true : false
  }

  if (to.meta.requiresAuth && !loggedIn) {
    next('/pages/login')
  } else {
    if (to.meta.requiresAdmin && !isAdmin) {
      console.log('Requiere y es admin')
      next({ path: '/' })
    }
    next()
  }
})

export default router
