/**
 * @fileoverview Contiene el archivo del store de Tarifas
 *
 * @version 1.0
 *
 * @author Raúl Ariel Rodríguez Barceló <ariel@aroba.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por Raúl Ariel Rodríguez Barceló
 */

import Rate from './rates.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchRate
   * @description Obtiene una lista de Tarifas
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchRate(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return Rate.getRates(params)
  },

  /**
   * @name addRate
   * @description Crea una nueva Tarifa
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear una nueva Tarifa
   */
  addRate(context, payload) {
    return Rate.postRate(payload.body)
  },

  /**
   * @name getRatesTransfer
   * @description Obtiene las tarifas del traslado
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para obtener tarifas del traslado
   */
  getRatesTransfer(context, payload) {
    return Rate.postRatesTransfer(payload)
  },

  /**
   * @name editRate
   * @description Edita una Tarifa
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Tarifa
   */
  editRate(context, payload) {
    return Rate.patchRate(payload.body, payload.id)
  },

  /**
   * @name deleteRate
   * @description Elimina una Tarifa
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Tarifa
   */
  deleteRate(context, payload) {
    return Rate.deleteRate(payload.id)
  },
}
