/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de zonas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import ZoneService from './zone.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchZones
   * @description Obtiene una lista de zonas
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchZones(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ZoneService.getZones(params)
  },

  /**
   * @name addZone
   * @description Crea un nueva zona
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear una nueva zona
   */
  addZone(context, payload) {
    return ZoneService.postZone(payload.body)
  },

  /**
   * @name editZone
   * @description Edita una zona
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una zona
   */
  editZone(context, payload) {
    return ZoneService.patchZone(payload.body, payload.id)
  },

  /**
   * @name deleteZone
   * @description Elimina una zona
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar una zona
   */
  deleteZone(context, payload) {
    return ZoneService.deleteZone(payload.id)
  },

  /**
   * @name pickupZone
   * @description Obtiene el tiempo necesario para el pickup
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar una zona
   */
  pickupZone(_, payload) {
    return ZoneService.pickupZone(payload.id)
  },
}
