/**
 * @fileoverview Contiene los servicios de consumo a la API para Modulo de Hoteles
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import HotelService from './hotels.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchHotels
   * @description Obtiene una lista de Hoteles
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchHotels(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return HotelService.getHotels(params)
  },

  /**
   * @name addHOtel
   * @description Crea un nuevo Hotel
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Genero
   */
  addHotel(_, payload) {
    return HotelService.postHotel(payload.body)
  },

  /**
   * @name editHotel
   * @description Edita un Hotel
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Genero existente
   */
  editHotel(_, payload) {
    return HotelService.patchHotel(payload.body, payload.id)
  },

  /**
   * @name deleteHotel
   * @description Elimina un Hotel
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Genero
   */
  deleteHotel(_, payload) {
    return HotelService.deleteHotel(payload.id)
  },
}
