/**
 * @fileoverview Contiene aciones del store de módulos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import ModuleService from './module.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchModules
   * @description Obtiene una lista de módulos
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchModules(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return ModuleService.getModules(params)
  },

  /**
   * @name addModule
   * @description Crea un nuevo módulo
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo módulo
   */
  addModule(_, payload) {
    return ModuleService.postModule(payload.body)
  },

  /**
   * @name editModule
   * @description Edita un módulo
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un módulo
   */
  editModule(_, payload) {
    return ModuleService.patchModule(payload.body, payload.id)
  },

  /**
   * @name deleteModule
   * @description Elimina un módulo
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un módulo
   */
  deleteModule(_, payload) {
    return ModuleService.deleteModule(payload.id)
  },
}
