/**
 * @fileoverview Contiene los servicios de consumo a la API para de traslados
 *
 * @version 1.0
 *
 * @author Omar Alberto Quijano Carballo
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por Omar Alberto Quijano Carballo
 */

import api from '@/services/api'
const END_POINT = '/traslados'

export default {
  /**
   * @name getTransfers
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} jwt JSON WEB Token válido
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getTransfers(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postTransfer
   * @description Crea un nuevo documento
   * @param {*} jwt JSON WEB Token válido
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postTransfer(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchTransfer
   * @description Actualiza un documento por su id
   *
   * @param {*} jwt JSON WEB Token válido
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchTransfer(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name getTransfersType
   * @description Obtiene un listado de tipos de traslados
   *
   * @param {*} jwt JSON WEB Token válido
   * @returns {array} array con el resultado de la consulta
   */
  getTransfersType(jwt) {
    api.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    return api.get(END_POINT + '/tipos')
  },
}
