/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de cupones
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import CouponService from './coupons.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchCoupons
   * @description Obtiene una lista de Cupones
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchCoupons(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return CouponService.getCoupons(params)
  },

  /**
   * @name addCoupon
   * @description Crea un nuevo cupón
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo cupón
   */
  addCoupon(_, payload) {
    return CouponService.postCoupon(payload.body)
  },

  /**
   * @name editCoupon
   * @description Edita un nuevo cupón
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un cupón existente
   */
  editCoupon(_, payload) {
    return CouponService.patchCoupon(payload.body, payload.id)
  },

  /**
   * @name deleteCoupon
   * @description Elimina un cupón
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un cupón
   */
  deleteCoupon(_, payload) {
    return CouponService.deleteCoupon(payload.id)
  },

  /**
   * @name validateCoupon
   * @description Valida un cupón
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para validar un cupón
   */
  validateCoupon(_, payload) {
    return CouponService.validateCoupon(payload.id)
  },
}
