/**
 * @fileoverview Contiene los servicios de consumo a la API para Marca de Vehiculos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = 'vehiculos/marcas'

export default {
  /**
   * @name getBrandsVehicle
   * @description Obtienen una lista de Marcas de Vehiculos
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getBrandsVehicle(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }

    return api.get(END_POINT)
  },

  /**
   * @name postBrandVehicle
   * @description Se crea la Marca de Vehiculo
   *
   * @param {*} body Input del formulario -> Propiedades para crear la Marca de Vehiculo
   *
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postBrandVehicle(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchBrandVehicle
   * @description Se actualiza una Marca de Vehiculo
   *
   * @param {*} body Input del formulario -> Propiedades para actualizar la Marca de Vehiculo
   * @param {*} id this.editedItem._id -> id de la Marca de Vehiculo que se va a actualizar
   *
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchBrandVehicle(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteBrandVehicle
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   *
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteBrandVehicle(id) {
    return api.delete(END_POINT + '/' + id)
  },
}
