/**
 * @fileoverview Contiene los servicios de consumo a la API de vuelos AerodataBox
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import axios from 'axios'
import api from '@/services/api'
const END_POINT = '/transportadoras'
// const baseURL = 'https://eosserver.rbsistemas.mx/graphql'
// const baseURL = 'http://localhost:3000/graphql'

/**
 * Executes a GraphQL query with the given query string and variables.
 * @param {string} query - The GraphQL query string.
 * @param {Object} variables - The variables to be passed along with the query.
 * @returns {Promise} - A promise that resolves with the API response.
 *
 * @example
 * // Execute a GraphQL query with variables
 * executeGraphQLQuery(query, variables)
 *   .then(response => console.log(response.data))
 *   .catch(error => console.error(error));
 */
function executeGraphQLQuery(query, payload) {
  const { variables, apiUrl, writeApiKey } = payload
  const data = JSON.stringify({ query, variables })

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: apiUrl + '/graphql',
    headers: {
      'write-api-key': writeApiKey,
      'Content-Type': 'application/json',
    },
    data,
  }

  return axios(config)
}

export default {
  /**
   * Get all carriers via API request
   * @param {Object} params - The object contaning the parameters information
   * @returns {Promise} - A promise with carriers
   *
   * @example
   * // Get carriers
   * const carriers = getCarriers(params)
   */
  getCarriers(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   * Sends a single transfer to a provider via an API request.
   * @param {Object} variables - The object containing the transfer information.
   * @returns {Promise} - A promise that resolves with the API response or an error if the request fails.
   *
   * @example
   * // Send a transfer to a provider
   * sendTransferToProvider(variables)
   *   .then(response => console.log(response.data))
   *   .catch(error => console.error(error));
   */
  sendTransferToProvider(variables) {
    const query = `
      mutation CreateTransfer($transferInfo: CreateTransferInput!) {
        createTransfer(createTransferInput: $transferInfo) {
          numero
          nombre
          origen
          destino
          fecha
          hora
          pickup
          pasajeros
          aerolinea
          vuelo
          reserva_id
          balance
          estatus
        }
      }`

    return executeGraphQLQuery(query, variables)
  },

  /**
   * Sends multiple transfers to a provider via an API request.
   * @param {Object} variables - The object containing the multiple transfers information.
   * @returns {Promise} - A promise that resolves with the API response or an error if the request fails.
   * @type GraphQL
   *
   * @example
   * // Send multiple transfers to a provider
   * sendMultipleTransfersToProvider(variables)
   *   .then(response => console.log(response.data))
   *   .catch(error => console.error(error));
   */
  sendMultipleTransfersToProvider(payload) {
    /* const query = `
      mutation CreateMultipleTransfers($TransfersInfo: CreateMultipleTransfersInput!) {
        createMultipleTransfers(createMultipleTransfersInput: $TransfersInfo) {
          _id
          numero
          nombre
          origen
          destino
          fecha
          hora
          pickup
          pasajeros
          aerolinea
          vuelo
          reserva_id
          balance
          unidad
          agencia
          estatus
        }
      }` */

    const query = `
      mutation CreateMultipleTransfers($TransfersInfo: CreateMultipleTransfersInput!) {
        createMultipleTransfers(createMultipleTransfersInput: $TransfersInfo) {
          _id
          origen
          destino
          reserva_id
        }
      }`
    return executeGraphQLQuery(query, payload)
  },
}
