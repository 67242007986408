/**
 * @fileoverview Contiene el archivo del store de Transportadora, sirve para enviar
 * traslados unicos o masivos a la transportadora
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import TransportadoraServices from '../../services/transportadora.services'
export const namespaced = true

export const actions = {
  getCarriers(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return TransportadoraServices.getCarriers(params)
  },

  /**
   * Sends a single transfer to a provider using the transport service.
   * @function
   * @param {Object} _ - The Vuex context object, not used in this action.
   * @param {Object} payload - The payload containing the transfer information.
   * @returns {Promise} - A promise that resolves with the API response or an error if the request fails.
   *
   * @example
   * // Send a transfer to a provider using the store action
   * this.$store.dispatch('transportadora/sendTransferToProvider', payload)
   *   .then(response => console.log(response.data))
   *   .catch(error => console.error(error));
   */
  sendTransferToProvider(_, payload) {
    return TransportadoraServices.sendTransferToProvider(payload)
  },

  /**
   * Sends multiple transfers to a provider using the transport service.
   * @function
   * @param {Object} _ - The Vuex context object, not used in this action.
   * @param {Object} payload - The payload containing the multiple transfers information.
   * @returns {Promise} - A promise that resolves with the API response or an error if the request fails.
   *
   * @example
   * // Send multiple transfers to a provider using the store action
   * this.$store.dispatch('transportadora/sendMultipleTransfersToProvider', payload)
   *   .then(response => console.log(response.data))
   *   .catch(error => console.error(error));
   */
  sendMultipleTransfersToProvider(_, payload) {
    return TransportadoraServices.sendMultipleTransfersToProvider(payload)
  },
}
