/**
 * @fileoverview Contiene los servicios de consumo a la API para módulos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/modulos'

export default {
  /**
   * @name getModules
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getModules(params) {
    if (params != null) {
      return api.get(END_POINT + '/' + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postModule
   * @description Crea un nuevo documento
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postModule(body) {
    return api.post(END_POINT + '/', body)
  },

  /**
   * @name patchModule
   * @description Actualiza un documento por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchModule(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteModule
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteModule(id) {
    return api.delete(END_POINT + '/' + id)
  },
}
