/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de transportadoras
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import CarrierService from './carriers.services'
export const namespaced = true

export const actions = {
  fetchCarrier(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return CarrierService.getCarriers(params)
  },
  addCarrier(_, payload) {
    return CarrierService.postCarrier(payload.body)
  },
  editCarrier(_, payload) {
    return CarrierService.patchCarrier(payload.body, payload.id)
  },
}
