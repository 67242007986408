/**
 * @fileoverview Contiene los servicios de consumo a la API de vuelos AerodataBox
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import axios from 'axios'
const baseURL = process.env.VUE_APP_URL_AERODATABOX
const ICAO = process.env.VUE_APP_RAPIDAPI_ICAO
const host = process.env.VUE_APP_RAPIDAPI_HOST
const key = process.env.VUE_APP_RAPIDAPI_KEY

const api = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-RapidAPI-Host': host,
    'X-RapidAPI-Key': key,
  },
  timeout: 10000,
})

export default {
  getFlights(from, to, params) {
    console.log(from)
    console.log(to)
    if (params != null) {
      return api.get('/flights/airports/icao/' + ICAO + '/' + from + '/' + to + params)
    }
    return api.get('/flights/airports/icao/' + ICAO + '/' + from + '/' + to)
  },
}
