/**
 * @fileoverview Contiene los servicios de consumo a la API para tipos de cambio
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import api from '@/services/api'
const END_POINT = '/divisas'
export default {
  /**
   * @name getExchangeRates
   * @description Obtiene una lista de Tipos de Cambio Divisas
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getExchangeRates(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postExchangeRate
   * @description Crea un nuevo Tipo de Cambio Divisa
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el Tipo de Cambio Divisa
   * @returns {json} json con el resultado de crear un nuevo Tipo de Cambio Divisa
   */
  postExchangeRate(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchExchangeRate
   * @description Actualiza un tipo de cambio por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el tipo de cambio
   * @param {*} id _id -> id del tipo de cambio que se va a actualizar
   * @returns {json} json con el resultado de actualizar el tipo de cambio
   */
  patchExchangeRate(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteExchangeRate
   * @description Elimina un tipo de cambio por su id
   *
   * @param {*} id _id -> id del tipo de cambio que se va a eliminar
   * @returns {json} json con el resultado de eliminar el tipo de cambio
   */
  deleteExchangeRate(id) {
    return api.delete(END_POINT + '/' + id)
  },
}
