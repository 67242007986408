/**
 * @fileoverview Contiene los servicios de consumo a la API para Cupones
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/operaciones'

export default {
  /**
   * @name postOperacion
   * @description Se crea el cupón
   *
   * @param {*} body Caracteristicas de la consulta
   */
  postOperacion(body) {
    return api.post(END_POINT, body)
  },

  /**
   * Updates the provider and vehicle data for an existing operation (coupon).
   * @function
   * @param {Object} body - The data to update the operation.
   * @param {string} id - The ID of the operation to be updated.
   * @returns {Promise} - A promise that resolves with the API response or an error if the request fails.
   *
   * @example
   * // Update the provider and vehicle data for an existing operation
   * import OperationServices from '@/services/OperationServices';
   * const body = { ... };
   * const _id = '1234567890';
   * OperationServices.updateProvider(body, _id)
   *   .then(response => console.log(response.data))
   *   .catch(error => console.error(error));
   */
  updateProvider(body, id) {
    return api.patch(`${END_POINT}/${id}`, body)
  },

  updateInfo(body) {
    return api.patch(`${END_POINT}/bulk`, body)
  },
}
