/**
 * @fileoverview Contiene aciones del store de tipos de localizaciónes
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import LocalService from './locales.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchLocales
   * @description Obtiene una lista de las localizaciónes
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchLocales(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return LocalService.getLocales(params)
  },

  /**
   * @name addLocalization
   * @description Crea una nueva Localización
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear una nueva Localización
   */
  addLocalization(context, payload) {
    return LocalService.PostLocale(payload.body)
  },

  /**
   * @name editLocalization
   * @description Edita una localización
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Localización
   */
  editLocalization(context, payload) {
    return LocalService.patchLocale(payload.body, payload.id)
  },

  /**
   * @name deleteLocalization
   * @description Elimina una Localización
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar una Localización
   */
  deleteLocalization(context, payload) {
    return LocalService.deleteLocale(payload.id)
  },
}
