/**
 * @fileoverview Contiene los servicios de consumo a la API para usuarios
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import api from '@/services/api'
const END_POINT = '/usuarios'

import axios from 'axios'
// const END_POINT = '/usuarios'
const baseURL = process.env.VUE_APP_BASE_URL_API

const apiUsers = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
})

//RGALA - Constante  Axio para guardar el Avatar de un usuario - 11/Sep/2020
const apiUsersAvatar = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  timeout: 0,
})

export default {
  /**
   * @name getUsers
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getUsers() {
    return api.get(END_POINT)
  },

  /**
   * @name postUser
   * @description Crea un nuevo documento
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postUser(body) {
    return api.post(END_POINT + '/', body)
  },

  /**
   * @name patchUser
   * @description Actualiza un documento por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchUser(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteUser
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteUser(id) {
    return api.delete(END_POINT + '/' + id)
  },

  /**
   * @name inviteUser
   * @description Envia un correo de invitacion para registro a un usuario
   *
   * @param {*} body Correo del usuario que se desea invitar
   */
  inviteUser(body) {
    return apiUsers.post(END_POINT + '/invitacion', body)
  },

  /**
   * @name checkInvitation
   * @description Verifica la llave de invitacion
   *
   * @param {*} id api-key de invitacion
   */
  checkInvitation(id) {
    api.defaults.headers.common['api-key'] = id
    return api.get(END_POINT + '/invitacion')
  },

  /**
   * @name registerUser
   * @description Registra un usuario con los campos minimos
   *
   * @param {*} id api-key de invitacion
   * @param {*} body Informacion del usuario
   */
  registerUser(id, body) {
    api.defaults.headers.common['api-key'] = id
    return api.post(END_POINT + '/registro', body)
  },

  /**
   * @name getUserPermissions
   * @description Obtiene los permisos del usuario
   *
   * @param {*} id id del usuario
   */
  getUserPermissions(id) {
    return api.get(END_POINT + '/permisos/' + id)
  },

  //RGALA - Servicio para obtener un solo usuario mediante el Id - 11/Sep/2020
  /**
   * @name getUsers
   * @description Obtiene un documento segun el id
   *
   * @param {*} id Id del usuario deseado
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getUsersId(id) {
    return apiUsers.get(END_POINT + '/' + id)
  },

  //RGALA - Servicio para guardar el Avatar de un usuario - 11/Sep/2020
  postUserAvatar(jwt, body) {
    apiUsersAvatar.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    return apiUsersAvatar.post(END_POINT + '/imagen/upload', body)
  },
}
