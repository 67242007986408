/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de modelos de idiomas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import LanguajeService from './languaje.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchLanguajes
   * @description Obtiene una lista de Idiomas
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchLanguajes(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return LanguajeService.getLanguajes(params)
  },

  /**
   * @name addLanguaje
   * @description Crea un nuevo idioma
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo idioma
   */
  addLanguaje(_, payload) {
    return LanguajeService.postLanguaje(payload.body)
  },

  /**
   * @name editLanguaje
   * @description Edita un nuevo idioma
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un idioma existente
   */
  editLanguaje(_, payload) {
    return LanguajeService.patchLanguaje(payload.body, payload.id)
  },

  /**
   * @name deleteLanguaje
   * @description Elimina un idioma
   *
   * @param {*} context _Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un idioma
   */
  deleteLanguaje(_, payload) {
    return LanguajeService.deleteLanguaje(payload.id)
  },
}
