/**
 * @fileoverview Contiene el archivo del store de Operaciones
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import OperationServices from './operations.services'
export const namespaced = true

export const actions = {
  /**
   * Retrieves the transfers for an operation.
   * @function
   * @param {*} _ - The Vuex context object, not used in this action.
   * @param {Object} payload - The data for the operation request.
   * @returns {Promise} - A promise that resolves with the API response or an error if the request fails.
   *
   * @example
   * // Get transfers for an operation
   * this.$store.dispatch('operation/getTransfersOperation', payload)
   *   .then(response => console.log(response.data))
   *   .catch(error => console.error(error));
   */
  getTransfersOperation(_, payload) {
    return OperationServices.postOperacion(payload)
  },

  /**
   * Assigns a provider to an existing operation.
   * @function
   * @param {*} _ - The Vuex context object, not used in this action.
   * @param {Object} payload - The data to update the operation and the operation ID.
   * @param {Object} payload.body - The data to update the operation.
   * @param {string} payload.id - The ID of the operation to be updated.
   * @returns {Promise} - A promise that resolves with the API response or an error if the request fails.
   *
   * @example
   * // Assign a provider to an existing operation
   * const payload = { body: { ... }, _id: '1234567890' };
   * this.$store.dispatch('operations/assignProvider', payload)
   *   .then(response => console.log(response.data))
   *   .catch(error => console.error(error));
   */
  assignProvider(_, payload) {
    return OperationServices.updateProvider(payload.body, payload.id)
  },

  updateInfo(_, payload) {
    return OperationServices.updateInfo(payload)
  },
}
