import * as airports from '@/components/ventas/catalogos/aeropuertos/aeropuerto.store'
import * as extraCategory from '@/components/almacen/catalogos/categoria_extras/extraCategory.store'
import * as productCategory from '@/components/almacen/catalogos/categoria_productos/productCategory.store'
import * as extra from '@/components/almacen/catalogos/extras/extra.store'
import * as product from '@/components/almacen/catalogos/productos/product.store'
import * as localization from '@/components/configuration/locales/locale.store'
import * as client from '@/components/ventas/catalogos/clientes/client.store'
import * as fleet from '@/components/ventas/catalogos/flotillas/fleet.store'
import * as hotel from '@/components/ventas/catalogos/hoteles/hotel.store'
import * as languaje from '@/components/ventas/catalogos/idiomas/languaje.store'
import * as brandVehicle from '@/components/ventas/catalogos/marca_vehiculo/brandVehicle.store'
//import * as modelVehicle from './modules/ventas/modelVehicle'
import * as modelVehicle from '@/components/ventas/catalogos/modelo_vehiculo/modelVehicle'
import * as country from '@/components/ventas/catalogos/paises/country.store'
import * as service from '@/components/ventas/catalogos/servicios/services'
import * as rate from '@/components/ventas/catalogos/tarifas/rate.store'
// import * as drawer from './modules/settings/drawer.store'
//import * as exchangeRate from '@/services/ventas/ExchangeRates'
import * as module from '@/components/configuration/modules/module.store'
import * as exchangeRate from '@/components/ventas/catalogos/tipo_cambio/exchangeRate.store'
import * as vehicleType from '@/components/ventas/catalogos/tipo_vehiculos/vehicleType.store'
import * as zone from '@/components/ventas/catalogos/zonas/zone.store'
import * as coupon from '@/components/ventas/modulos/cupones/coupon.store.js'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as system from '../components/configuration/systems/system.store'
import * as user from '../components/configuration/users/user.store.js'
import * as dashboard from '../components/dashboard/dashboard.store'
import * as ferryTicket from '../components/ventas/catalogos/boletos/ferryTicket.store'
import * as gender from '../components/ventas/catalogos/generos/gender.store'
import * as transfer from '../components/ventas/catalogos/traslados/transfer.store'
import * as transferReservation from '../components/ventas/modulos/reservas/transfers_reservation.store'
import * as aerodatabox from './modules/aerodatabox.store'
import * as auth from './modules/auth.js'
import * as commun from './modules/commun'
import * as group from './modules/group'
import * as permit from './modules/permiso.store'
import * as userRole from './modules/userRole'
import * as vehicle from './modules/vehicle'
import * as socket from './modules/socket/socket.store'
import * as operation from '../components/ventas/modulos/operaciones/operation.store'
import * as transportadora from './modules/transportadora.store'
import * as carrier from '@/components/ventas/catalogos/transportadoras/carrier.store'
/* import SecureLS from 'secure-ls'
var ls = new SecureLS({ isCompression: false }) */

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    adminMode: false,
    barColor: 'rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)',
    barImage: process.env.VUE_APP_BAR_IMAGE,
    drawer: null,
    menu: 'dasboard',
    snackbar: {
      visible: false,
      color: 'success',
      text: null,
      timeout: 6000,
      multiline: false,
    },
    tab: 0,
    isConnected: false,
    socketMessage: '',
  },
  mutations: {
    ALERT(state, payload) {
      state.snackbar.text = payload.text
      if (payload.text.length > 50) {
        state.snackbar.multiline = true
      }

      // Si es mas de 50 caracteres definirlo como multilinea
      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline
      }

      // Color para mensajes de error o éxito
      if (payload.color) {
        state.snackbar.color = payload.color
      }

      // Tiempo de duración
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout
      }
      state.snackbar.visible = true
    },
    CLOSE_ALERT(state) {
      state.snackbar.visible = false
      state.snackbar.multiline = false
      state.snackbar.text = null
    },
    SET_ADMIN_MODE(state, payload) {
      state.adminMode = payload
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_MENU(state, payload) {
      state.menu = payload
    },
    SET_MENU_TAB(state, payload) {
      state.tab = payload
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload
    },
    SOCKET_CONNECT(state) {
      state.isConnected = true
    },
    SOCKET_DISCONNECT(state) {
      state.isConnected = false
    },
    SOCKET_MESSAGECHANNEL(state, message) {
      state.socketMessage = message
    },
  },
  actions: {},
  modules: {
    airports,
    aerodatabox,
    extraCategory,
    auth,
    brandVehicle,
    carrier,
    client,
    commun,
    country,
    coupon,
    dashboard,
    // drawer,
    exchangeRate,
    extra,
    ferryTicket,
    fleet,
    gender,
    group,
    hotel,
    languaje,
    localization,
    modelVehicle,
    module,
    operation,
    permit,
    product,
    productCategory,
    rate,
    service,
    system,
    socket,
    transfer,
    transportadora,
    transferReservation,
    user,
    userRole,
    vehicle,
    vehicleType,
    zone,
  },
})
