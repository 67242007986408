import AuthService from '@/services/auth.services.js'
export const namespaced = true

export const state = {
  user: '',
  // Los puse yoni
  refresh_token: '',
  access_token: '',
  loggedInUser: {},
  isAuthenticated: false,
  // *******
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    var created = new Date().getTime()
    userData.created = created

    state.user = userData
    localStorage.setItem('user', JSON.stringify(userData))
  },
  LOGOUT(state) {
    localStorage.removeItem('user')
    localStorage.removeItem('vuex')
    localStorage.removeItem('sessionID')
    state.user = ''
  },
  //***** las puse yoni */
  setRefreshToken: function (state, refreshToken) {
    state.refresh_token = refreshToken
  },
  setAccessToken: function (state, accessToken) {
    state.access_token = accessToken
  },
  // sets state with user information and toggles
  // isAuthenticated from false to true
  setLoggedInUser: function (state, user) {
    state.loggedInUser = user
    state.isAuthenticated = true
  },
  // delete all auth and user information from the state
  clearUserData: function (state) {
    state.refresh_token = ''
    state.access_token = ''
    state.loggedInUser = {}
    state.isAuthenticated = false
  },
  //***** Termina las que puse yoni */
}

export const actions = {
  getValidToken({ commit }) {
    const user = JSON.parse(localStorage.getItem('user'))
    const time = user.created
    const now = new Date().getTime()
    const expire = user.expira

    var sec_diff = (now - time) / 1000
    const remainingTime = expire - sec_diff
    if (remainingTime > 60) {
      // send valid token
      console.log('Entrega token valido')
      return { estado: 1, jwt: user.jwt }
    }
    // request jwt
    console.log('Solicita nuevo token')
    const rft = user.rft
    // console.log('refresh', rft)
    let body = { token: rft }
    return AuthService.refreshToken(body)
      .then((response) => {
        // console.log('Nuevo tokens', response.data.expira)
        user.jwt = response.data.jwt
        user.expira = response.data.expira
        commit('SET_USER_DATA', user)
        return response.data
      })
      .catch((error) => {
        return error.response.data
      })
  },
  refreshToken({ commit, state }) {
    const user = JSON.parse(localStorage.getItem('user'))
    // console.log(`Solicita refresh tokens`)
    return AuthService.refreshToken({ token: state.refresh_token })
      .then((response) => {
        // console.log(`jwt: ${response.data.jwt}`)
        user.jwt = response.data.jwt
        user.expira = response.data.expira
        commit('SET_USER_DATA', user)
        commit('setAccessToken', response.data.jwt)
        //return response.data
      })
      .catch((error) => {
        return error.response.data
      })
  },
  login({ commit }, credentials) {
    return AuthService.loginUser(credentials)
      .then(({ data }) => {
        console.info('entro a login')
        commit('SET_USER_DATA', data)
        //** Lo puse yoni */
        commit('setRefreshToken', data.rft)
        commit('setAccessToken', data.jwt)
        commit('setLoggedInUser', true)
        //** Termina lo que puse yoni */
      })
      .catch((error) => {
        throw error
      })
  },
  logout({ commit }) {
    commit('LOGOUT')
  },

  changePassword(context, payload) {
    return AuthService.changePassword(payload)
  },

  resetPassword(context, payload) {
    return AuthService.resetPassword(payload)
  },
}

export const getters = {
  loggedInUser: (state) => state.loggedInUser,
  isAuthenticated: (state) => state.isAuthenticated,
  accessToken: (state) => state.access_token,
  refreshToken: (state) => state.refresh_token,
}
