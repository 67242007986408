/**
 * @fileoverview Contiene el archivo del store de Extras
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import ExtraService from './extra.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchExtras
   * @description Obtiene una lista de Extras
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchExtras(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ExtraService.getExtra(params)
  },

  /**
   * @name addExtra
   * @description Crea un nuevo Extra
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Producto
   */
  addExtra(context, payload) {
    return ExtraService.postExtra(payload.body)
  },

  /**
   * @name editExtra
   * @description Edita un Extra
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Producto
   */
  editExtra(context, payload) {
    return ExtraService.patchExtra(payload.body, payload.id)
  },

  /**
   * @name deleteExtra
   * @description Elimina un Producto
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Producto
   */
  deleteExtra(context, payload) {
    return ExtraService.deleteExtra(payload.id)
  },

  /**
   * @name addImageProduct
   * @description CArga una imagen
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Imagen a cargar
   */
  addImageExtra(context, payload) {
    return ExtraService.postImageExtra(payload.body)
  },
}
