import axios from 'axios'
const baseURL = process.env.VUE_APP_BASE_URL_API

const apiCommun = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
})

export default {
  getGeneros(jwt, params) {
    apiCommun.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    if (params != null) {
      return apiCommun.get('/generos' + params)
    }
    return apiCommun.get('/generos')
  },
  getIdiomas(jwt, params) {
    apiCommun.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    if (params != null) {
      return apiCommun.get('/idiomas' + params)
    }
    return apiCommun.get('/idiomas')
  },
  getPaises(jwt, params) {
    apiCommun.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    if (params != null) {
      return apiCommun.get('/paises' + params)
    }
    return apiCommun.get('/paises')
  },
}
