import CommunServices from '@/services/CommunServices'
export const namespaced = true

export const actions = {
  fetchGenero(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return CommunServices.getGeneros(payload.jwt, params)
  },
  fetchIdioma(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return CommunServices.getIdiomas(payload.jwt, params)
  },
  fetchPais(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return CommunServices.getPaises(payload.jwt, params)
  },
}
