/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store del Dashboard
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import DashboardServices from './dashboard.services'
export const namespaced = true

export const actions = {
  /**
   * Obtiene el total de reservas por tipo de traslado (llegada, salida o terrestre)
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchTransfersTypes(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return DashboardServices.getSemanalTotalTiposTraslados(params)
  },

  /**
   * Obtiene el numero de reservas realizadas agrupadas por dispositivo
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchDeviceTypes(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return DashboardServices.getSemanalTotalDispositivos(params)
  },

  /**
   * Obtiene el total de reservas por tipo de moneda
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchCurrencyTypes(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return DashboardServices.getTotalTiposMoneda(params)
  },

  /**
   * Obtiene el total de reservas por tipo de pago
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchPaymentTypes(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return DashboardServices.getTotalTiposPago(params)
  },

  /**
   * Obtiene todas las estadisticas para el dashboard
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchStatistics(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return DashboardServices.getEstadisticas(params)
  },

  /**
   * Obtiene el total de reservas por dia en un lapso de 7 dias
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchWeekReservations(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return DashboardServices.getSemanalReservas(params)
  },
}
