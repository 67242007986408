/**
 * @fileoverview Contiene el archivo del store de Categorías de Extras
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import extraCategory from './extraCategory.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchExtraCategory
   * @description Obtiene una lista de Categorias de Extras
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchExtraCategory(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return extraCategory.getExtraCategories(params)
  },

  /**
   * @name addExtraCategory
   * @description Crea una nueva categoría de extras
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear una nueva categoría de extras
   */
  addExtraCategory(context, payload) {
    return extraCategory.postExtraCategory(payload.body)
  },

  /**
   * @name editExtraCategory
   * @description Edita una nueva Categoria de Extras
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Categoria de Extras
   */
  editExtraCategory(context, payload) {
    return extraCategory.patchExtraCategory(payload.body, payload.id)
  },

  /**
   * @name deleteExtraCategory
   * @description Elimina una Categoria de Extras
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Categoria de Extras
   */
  deleteExtraCategory(context, payload) {
    return extraCategory.deleteExtraCategory(payload.body, payload.id)
  },
}
