/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de marcas de vehiculos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import BrandVehicleService from './brandVehicle.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchBrandsVehicle
   * @description Obtiene una lista de Marcas de Vehiculos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchBrandsVehicle(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return BrandVehicleService.getBrandsVehicle(params)
  },

  /**
   * @name addBrandVehicle
   * @description Crea una nueva Marca de vehiculo
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear una nueva Marca de Vehiculo
   */
  addBrandVehicle(context, payload) {
    return BrandVehicleService.postBrandVehicle(payload.body)
  },

  /**
   * @name editBrandVehicle
   * @description Edita una Marca de vehiculo
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar una Marca de Vehiculo existente
   */
  editBrandVehicle(context, payload) {
    return BrandVehicleService.patchBrandVehicle(payload.body, payload.id)
  },

  /**
   * @name deleteBrandVehicle
   * @description Elimina una Marca de Vehiculo
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar una Marca de Vehiculo
   */
  deleteBrandVehicle(context, payload) {
    console.log('On store file: ', payload.id)
    return BrandVehicleService.deleteBrandVehicle(payload.id)
  },
}
