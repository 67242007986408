import i18n from '@/i18n'
import '@/sass/overrides.sass'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

// const theme = {
//   primary: '#E91E63',
//   secondary: '#9C27b0',
//   accent: '#9C27b0',
//   info: '#00CAE3',
// }

// const theme = {
//   primary: '#1976D2',
//   secondary: '#424242',
//   accent: '#82B1FF',
//   error: '#FF5252',
//   info: '#2196F3',
//   success: '#4CAF50',
//   warning: '#FFC107',
// }
//console.warn(process.env.VUE_APP_THEME)

const theme = {
  primary: process.env.VUE_APP_PRIMARY,
  secondary: process.env.VUE_APP_SECONDARY,
  accent: process.env.VUE_APP_ACCENT,
  error: process.env.VUE_APP_ERROR,
  warning: process.env.VUE_APP_WARNING,
  info: process.env.VUE_APP_INFO,
  success: process.env.VUE_APP_SUCCESS,
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
