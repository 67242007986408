/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de modelos de vehiculos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import ModelVehicleService from './ModelVehicleServices'
export const namespaced = true

export const actions = {
  /**
   * @name fetcModelsVehicle
   * @description Obtiene una lista de Modelos de Vehiculos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchModelsVehicle(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ModelVehicleService.getModelsVehicle(params)
  },

  /**
   * @name addModelVehicle
   * @description Crea un nuevo Modelo de vehiculo
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Modelo de Vehiculo
   */
  addModelVehicle(context, payload) {
    return ModelVehicleService.postModelVehicle(payload.body)
  },

  /**
   * @name editModelVehicle
   * @description Edita un nuevo Modelo de vehiculo
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Modelo de Vehiculo existente
   */
  editModelVehicle(context, payload) {
    return ModelVehicleService.patchModelVehicle(payload.body, payload.id)
  },

  /**
   * @name deleteModelVehicle
   * @description Elimina un pais
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Modelo de Vehiculo
   */
  deleteModelVehicle(context, payload) {
    return ModelVehicleService.deleteModelVehicle(payload.id)
  },
}
