/**
 * @fileoverview Contiene los servicios de consumo a la API para de zonas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/zonas'

export default {
  /**
   * @name getZones
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getZones(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postZone
   * @description Crea un nuevo documento
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postZone(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchZone
   * @description Actualiza un documento por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchZone(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteZone
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteZone(id) {
    return api.delete(END_POINT + '/' + id)
  },

  /**
   * @name pickupZone
   * @description Obtiene el tiempo necesario para el pickup
   *
   * @param {*} id _id -> id de la zona de la que se desea el pickup
   * @returns {json} json con el resultado de eliminar el documento
   *
   * @example
   * // Obtener tiempo previo para pickup
   * const hours = pickupZone(id)
   */
  pickupZone(id) {
    return api.get(END_POINT + '/pickup/' + id)
  },
}
