/**
 * @fileoverview Contiene el archivo del store de Productos
 *
 * @version 1.0
 *
 * @author Raúl Ariel Rodríguez Barceló <ariel@aroba.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por Raúl Ariel Rodríguez Barceló
 */

import ProductServices from './product.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchProduct
   * @description Obtiene una lista de Productos
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchProduct(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ProductServices.getProduct(params)
  },

  /**
   * @name addProduct
   * @description Crea un nuevo Producto
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Producto
   */
  addProduct(context, payload) {
    return ProductServices.postProduct(payload.body)
  },

  /**
   * @name editProduct
   * @description Edita un Producto
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Producto
   */
  editProduct(context, payload) {
    return ProductServices.patchProduct(payload.body, payload.id)
  },

  /**
   * @name deleteProduct
   * @description Elimina un Producto
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Producto
   */
  deleteProduct(context, payload) {
    return ProductServices.deleteProduct(payload.id)
  },

  /**
   * @name addImageProduct
   * @description Carga una imagen
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Imagen a cargar
   */
  addImageProduct(context, payload) {
    return ProductServices.postImageProduct(payload.body)
  },
}
