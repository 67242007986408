<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  // sockets: {
  //   connect() {
  //     // Fired when the socket connects.
  //     console.info(`Conectado desde app.vue`)
  //   },
  //   /* session({ sessionID, userID, username }) {
  //     console.info(`SessionID: ${sessionID} desde app.vue`)
  //     console.info(`userID: ${userID} desde app.vue`)
  //     console.info(`userName: ${username} desde app.vue`)
  //     // attach the session ID to the next reconnection attempts

  //     this.$socket.auth = { sessionID }
  //     // store it in the localStorage
  //     localStorage.setItem('sessionID', sessionID)
  //     // save the ID of the user
  //     this.$socket.userID = userID
  //   }, */
  //   serverConection(data) {
  //     console.info(`Id: ${data}`)
  //   },

  //   srvReservationCreated(data) {
  //     console.info(data)
  //   },

  //   disconnect() {
  //     console.info(`Desonectado desde app.vue`)
  //   },
  // },
  created() {
    /* if (process.env.VUE_APP_USE_SOCKET === true) {
      const sessionID = localStorage.getItem('sessionID')
      const loggedIn = localStorage.getItem('user')
      console.info('Entro en created')
      if (sessionID && loggedIn) {
        // this.usernameAlreadySelected = true
        console.info('Entro en condicional')
        this.$socket.auth = { sessionID }
        this.$socket.connect()
      }
    } */
  },
}
</script>
