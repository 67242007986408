/**
 * @fileoverview Contiene las acciones para consumo de la API aerodatabox
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import AerodataBox from '@/services/aerodatabox.service'
export const namespaced = true

export const actions = {
  fetchFlights(_, payload) {
    let params = 'params' in payload ? payload.params : null
    console.log(params)
    return AerodataBox.getFlights(payload.from, payload.to, params)
  },
}
