/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de traslados
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import TransferService from './transfers.services'
export const namespaced = true

export const actions = {
  fetchTransfer(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return TransferService.getTransfers(params)
  },
  addTransfer(context, payload) {
    return TransferService.postTransfer(payload.body)
  },
  editTransfer(context, payload) {
    return TransferService.patchTransfer(payload.body, payload.id)
  },
  fetchTransferTypes(context, payload) {
    return TransferService.getTransfersType(payload.jwt)
  },
}
