/**
 * @fileoverview Contiene los servicios de consumo a la API para el Dashboard
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/dashboard'

export default {
  /**
   * Obtiene las estadisticas para el dashboard
   * ultimos dias
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getEstadisticas(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT + '/estadisticas')
  },

  /**
   * @name getSemanalReservas
   * @description Obtienen las etiquetas y series de reservas de los siete
   * ultimos dias
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getSemanalReservas(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },

  /**
   *  Obtienen el total de reservas realizadas por tipo de dispositivo
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getSemanalTotalDispositivos(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT + '/dispositivos')
  },

  /**
   *  Obtienen el total de reservas realizadas por tipo de traslado
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getSemanalTotalTiposTraslados(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT + '/traslados')
  },

  /**
   *  Obtienen el total de reservas realizadas por tipo de moneda
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getTotalTiposMoneda(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT + '/monedas')
  },

  /**
   *  Obtienen el total de reservas realizadas por tipo de pago
   *
   * @param {*} params campo1 -> Parametros para poblar el Select
   *
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getTotalTiposPago(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT + '/pagos')
  },
}
