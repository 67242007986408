/**
 * @fileoverview Contiene los servicios de consumo a la API para aeropuertos
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/aeropuertos'

export default {
  /**
   * @name getAirports
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getAirports(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }
    return api.get(END_POINT)
  },
}
