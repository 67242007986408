/**
 * @fileoverview Contiene los servicios de consumo a la API para Idiomas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import api from '@/services/api'
const END_POINT = '/idiomas'

export default {
  /**
   * @name getLanguajes
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getLanguajes(params) {
    if (params != null) {
      return api.get(END_POINT + params)
    }

    return api.get(END_POINT)
  },

  /**
   * @name postLanguaje
   * @description Se crea el idioma
   *
   * @param {*} jwt JSON Web Token válido
   * @param {*} body Input del formulario -> Propiedades para crear el idioma
   */
  postLanguaje(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchGender
   * @description Se actualiza el idioma
   *
   * @param {*} jwt JSON Web Token válido
   * @param {*} body Input del formulario -> Propiedades para actualizar el idioma
   * @param {*} id this.editedItem._id -> id del idioma que se va a actualizar
   */
  patchLanguaje(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteLanguaje
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a eliminar
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteLanguaje(id) {
    return api.delete(END_POINT + '/' + id)
  },
}
