import VehicleService from '@/services/VehicleServices'
export const namespaced = true

export const actions = {
  // register({ commit }, credentials) {
  //   return VehicleService.registerVehicle(credentials).then(({ data }) => {
  //     commit('SET_Vehicle_DATA', data.usuario)
  //   })
  // },

  fetchVehicles(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return VehicleService.getVehicles(payload.jwt, params)
  },
  addVehicle(context, payload) {
    return VehicleService.postVehicle(payload.jwt, payload.body)
  },
  editVehicle(context, payload) {
    return VehicleService.patchVehicle(payload.jwt, payload.body, payload.id)
  },
}
