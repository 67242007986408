export const namespaced = true

export const state = {
  reservation: {},
}
export const mutations = {
  SOCKET_NEW_BOOKING(state, booking) {
    console.log('Nueva reserva desde el store')
    state.reservation = booking
  },
}
export const actions = {}
