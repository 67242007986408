/**
 * @fileoverview Contiene el archivo del store de Traslados
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express Mexico
 *
 * Historial
 *
 * ----
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import TransferServices from './transfers_reservation.services'
export const namespaced = true

export const state = {
  pickup_ida: '',
  pickup_vuelta: '',
  tickets: false,
  tipo_traslado: 'sencillo',
  zona: '',
  zona2: '',
}

export const mutations = {
  SET_PICKUP_IDA(state, pickup_ida) {
    state.pickup_ida = pickup_ida
  },
  SET_PICKUP_VUELTA(state, pickup_vuelta) {
    state.pickup_vuelta = pickup_vuelta
  },
  SET_TICKETS(state, tickets) {
    state.tickets = tickets
  },
  SET_TIPO_TRASLADO(state, tipo_traslado) {
    state.tipo_traslado = tipo_traslado
  },
  SET_ZONA(state, zona) {
    state.zona = zona
  },
  SET_ZONA2(state, zona) {
    state.zona2 = zona
  },
}

export const actions = {
  /**
   * @name fetchTransferReservation
   * @description Obtiene un listado de reservaciones de un traslado
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros poblar el select
   */
  fetchTransferReservation(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return TransferServices.getTransfersReservation(params)
  },

  /**
   * @name addTransfer
   * @description Crea una nueva reserva de traslados
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear una nueva reserva de traslados
   */
  addTransferReservation(_, payload) {
    return TransferServices.postTransfersReservation(payload.body)
  },

  patchTransferBooking(_, payload) {
    return TransferServices.patchTransfersBooking(payload.body, payload.id)
  },

  /**
   * @name fetchTransferDetails
   * @description Obtiene los detalles de la reserva de un traslado
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros poblar el select
   */
  fetchTransferDetails(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return TransferServices.getTransfersDetails(params)
  },

  /**
   * @name deleteTansferReservation
   * @description Elimina la reservacion de un traslado
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Genero
   */
  deleteTansferReservation(_, payload) {
    return TransferServices.deleteTransferReservation(payload.id)
  },

  /**
   * @name sendMailTansferReservation
   * @description Envia por correo una reserva por su id
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para enviar el correo
   */
  sendMailTansferReservation(_, payload) {
    return TransferServices.sendMailTransferReservation(payload.id)
  },

  /**
   * @name setTipoTraslado
   * @description Establece el tipo de traslado en el store
   *
   * @param {*} tipo_traslado tipo de traslado
   */
  setTipoTraslado({ commit }, tipo_traslado) {
    commit('SET_TIPO_TRASLADO', tipo_traslado)
  },

  /**
   * @name setZona
   * @description Establece la zona del traslado en el store
   *
   * @param {*} zona tipo de traslado
   */
  setZona({ commit }, zona) {
    commit('SET_ZONA', zona)
  },

  /**
   * @name getPDF
   * @description Obtiene el archivo PDF de una resrva por su id
   *
   * @param {*} _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para generar el PDF
   */
  getPDF(_, payload) {
    let params = 'params' in payload ? payload.params : null
    let id = payload.id
    return TransferServices.getPDF(id, params)
  },
}
