/**
 * @fileoverview Contiene aciones del store de usuarios
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import UserService from './user.services.js'
export const namespaced = true

export const state = {
  user: '',
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    var created = new Date().getTime()
    userData.created = created

    state.user = userData
    localStorage.setItem('user', JSON.stringify(userData))
    //axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
  },
}

export const actions = {
  /**
   * @name fetchUsers
   * @description Obtiene un listado de usuarios
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchUsers(_, payload) {
    return UserService.getUsers(payload)
  },

  //RGALA - Store para obtener un solo usuario mediante el Id - 11/Sep/2020
  /**
   * @name fetchUsersId
   * @description Obtiene un usuario por su Id
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para obtener el usuario
   */
  fetchUsersId(_, payload) {
    return UserService.getUsersId(payload.id)
  },

  /**
   * @name addUser
   * @description Crea un nuevo Usuario
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo sistema
   */
  addUser(_, payload) {
    return UserService.postUser(payload.body)
  },

  /**
   * @name editUser
   * @description Edita un usuario por su id
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un sistema
   */
  editUser(_, payload) {
    return UserService.patchUser(payload.body, payload.id)
  },

  /**
   * @name deleteUser
   * @description Elimina un usuario
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un sistema
   */
  deleteUser(_, payload) {
    return UserService.deleteUser(payload.id)
  },

  register({ commit }, credentials) {
    return UserService.registerUser(credentials).then(({ data }) => {
      commit('SET_USER_DATA', data.usuario)
    })
  },

  /**
   * @name fetchUserPermissions
   * @description Obtiene los permisos del usuario
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para obtener los permisos
   */
  fetchUserPermissions(_, payload) {
    return UserService.getUserPermissions(payload.id)
  },

  /**
   * @name inviteUser
   * @description Envia una invitación por correo electrónico a un usuario para que se
   * registre
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un sistema
   */
  inviteUser(_, payload) {
    return UserService.inviteUser(payload.body)
  },

  /**
   * @name checkInvitation
   * @description Verifica si la invitación para registrarse es válida
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros enviar el correo al usuario
   */
  checkInvitation(context, payload) {
    return UserService.checkInvitation(payload)
  },

  /**
   * @name registerUser
   * @description Registra a un usuario con los campos mínimos requeridos
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para registra a un usuario
   */
  registerUser(context, payload) {
    return UserService.registerUser(payload.id, payload.body)
  },

  //RGALA - Store para guardar el avatar de un usuario - 11/Sep/2020
  /**
   * @name loadAvatar
   * @description Guarda el avatar del usuario
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros cargar el avatar
   */
  loadAvatar(_, payload) {
    return UserService.postUserAvatar(payload.body)
  },
}

export const getters = {
  loggedIn(state) {
    return !!state.user
  },
}
