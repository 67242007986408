/**
 * @fileoverview Contiene los servicios de consumo a la API para reservas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import apiTransfers from '@/services/api'
const END_POINT = '/reservas'

export default {
  /**
   * @name getTransfersReservation
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getTransfersReservation(params) {
    if (params != null) {
      return apiTransfers.get(END_POINT + params)
    }
    return apiTransfers.get(END_POINT)
  },

  /**
   * @name postTransfersReservation
   * @description Se crea la reserva del traslado
   *
   * @param {*} jwt JSON Web Token válido
   * @param {*} body Input del formulario -> Propiedades para crear el idioma
   */
  postTransfersReservation(body) {
    return apiTransfers.post(END_POINT, body)
  },

  /**
   * @name patchTransfersBooking
   * @description Se actualiza la reserva del traslado
   *
   * @param {*} body Input del formulario -> Propiedades para crear el idioma
   *  @param {*} id Id de la reserva para actualizar
   */
  patchTransfersBooking(body, id) {
    return apiTransfers.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name getTransfersDetails
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getTransfersDetails(params) {
    console.log(END_POINT)
    if (params != null) {
      return apiTransfers.get(END_POINT + '/traslados' + params)
    }
    return apiTransfers.get(END_POINT + '/traslados')
  },

  /**
   * @name deleteTransferReservation
   * @description Elimina un documento por su id
   *
   * @param {id}  _id -> id del documento que se va a eliminar
   * @returns {json} json con el resultado de eliminar el documento
   */
  deleteTransferReservation(id) {
    return apiTransfers.delete(END_POINT + '/' + id)
  },

  /**
   * @name sendMailTransferReservation
   * @description Envia por correo una reserva por su id
   *
   * @param {id}  _id -> id del documento que se va a enviar
   * @returns {json} json con el resultado de enviar el documento
   */
  sendMailTransferReservation(id) {
    return apiTransfers.post(END_POINT + '/correo/' + id)
  },

  /**
   * @name getTransfersReservation
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getPDF(id, params) {
    if (params != null) {
      return apiTransfers.get(END_POINT + '/pdf/' + id + params, { responseType: 'blob' })
    }
    return apiTransfers.get(END_POINT)
  },
}
