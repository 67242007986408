/**
 * @fileoverview Contiene los configuracion de los interceptors
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import axios from 'axios'
import router from '../router'
import store from '../store'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  headers: {
    Accept: 'application/json',
  },
  // Aumentamos el tiempo de espera a 35 segundos para permitir un poco más de tiempo para las solicitudes lentas
  timeout: process.env.NODE_ENV === 'development' ? 35000 : 10000,
})

console.log('process.env.NODE_ENV')
console.log(process.env.NODE_ENV)
const MAX_REFRESH_ATTEMPTS = 3 // Nuevo: máximo número de intentos para refrescar el token.
let refreshAttempts = 0 // Nuevo: contador para los intentos de refrescar el token.

httpClient.interceptors.request.use(async (req) => {
  req.config = {
    ...(req.config ?? {}),
    start: Date.now(),
  }

  const token = store.getters['auth/accessToken']

  if (req.url.includes('upload')) {
    req.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  } else {
    req.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  }

  return req
})

httpClient.interceptors.response.use(
  (response) => {
    const end = Date.now()
    if (process.env.NODE_ENV === 'development') {
      // Nuevo: Solo mostrar el log en ambiente de desarrollo
      console.info(`Api Call ${response.config.url} took ${end - response.config.config.start}ms`)
    }
    return response
  },
  async (error) => {
    if (!error.response) {
      console.error('Error de red o de tiempo de espera:', error)
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (error.response.status === 401 && originalRequest.url.includes('/auth/token')) {
      store.commit('auth/clearUserData')
      store.commit('auth/LOGOUT')
      router.push({ name: 'Login' })
      return Promise.reject(error)
    } else if (error.response.status === 401 && refreshAttempts < MAX_REFRESH_ATTEMPTS) {
      refreshAttempts++
      await store.dispatch('auth/refreshToken')
      return httpClient(originalRequest)
    } else if (error.response.status === 500) {
      console.error('Error 500: Problema en el servidor')
    } else {
      console.error(`Error ${error.response.status}`)
    }

    refreshAttempts = 0 // Nuevo: Reseteamos el contador de intentos después de cada petición.

    return Promise.reject(error)
  }
)

export default httpClient
