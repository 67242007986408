import axios from 'axios'
const baseURL = process.env.VUE_APP_BASE_URL_API

const apiVehicles = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
})

export default {
  getVehicles(jwt, params) {
    apiVehicles.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    if (params != null) {
      return apiVehicles.get('/vehiculos/' + params)
    }
    return apiVehicles.get('/vehiculos')
  },
  postVehicle(jwt, body) {
    apiVehicles.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    return apiVehicles.post('/vehiculos/', body)
  },
  patchVehicle(jwt, body, id) {
    apiVehicles.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    return apiVehicles.patch('/vehiculos/' + id, body)
  },
}
