/**
 * @fileoverview Contiene aciones del store de sistemas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import SystemService from './system.services'
export const namespaced = true

export const actions = {
  /**
   * @name fetchSystems
   * @description Obtiene una lista de sistemas
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchSystems(_, payload) {
    let params = 'params' in payload ? payload.params : null
    return SystemService.getSystems(params)
  },

  /**
   * @name addSystem
   * @description Crea un nuevo sistema
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo sistema
   */
  addSystem(_, payload) {
    return SystemService.postSystem(payload.body)
  },

  /**
   * @name editSystem
   * @description Edita un sistema
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un sistema
   */
  editSystem(_, payload) {
    return SystemService.patchSystem(payload.body, payload.id)
  },

  /**
   * @name deleteSystem
   * @description Elimina un sistema
   *
   * @param {*} context _ Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un sistema
   */
  deleteSystem(_, payload) {
    return SystemService.deleteSystem(payload.id)
  },
}
