/**
 * @fileoverview Contiene los servicios de consumo a la API para extras
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

import api from '@/services/api'
const END_POINT = '/extras'

export default {
  /**
   * @name getExtra
   * @description Obtiene un listado de documentos segun parametros
   *
   * @param {*} params campo1 -> Parametros para poblar el select
   * @returns {array} devuelve un arreglo de json con los documentos
   */
  getExtra(params) {
    if (params != null) {
      return api.get(END_POINT + '/' + params)
    }
    return api.get(END_POINT)
  },

  /**
   * @name postExtra
   * @description Crea un nuevo documento
   *
   * @param {*} body Input del diálogo -> Propiedades para crear el documento
   * @returns {json} json con el resultado de crear un nuevo documento
   */
  postExtra(body) {
    return api.post(END_POINT, body)
  },

  /**
   * @name patchExtra
   * @description Actualiza un documento por su id
   *
   * @param {*} body Input del dialogo -> Propiedades para actualizar el documento
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  patchExtra(body, id) {
    return api.patch(END_POINT + '/' + id, body)
  },

  /**
   * @name deleteExtra
   * @description Elimina un documento por su id
   *
   * @param {*} id _id -> id del documento que se va a actualizar
   * @returns {json} json con el resultado de actualizar el documento
   */
  deleteExtra(id) {
    return api.delete(END_POINT + '/' + id)
  },

  /**
   * @name postImageExtra
   * @description Carga una imagen en el servidor
   *
   * @param {*} body Archivo a cargar en el servidor
   * @returns {json} json con el nombren de la imagen cargada
   */
  postImageExtra(body) {
    return api.post(END_POINT + '/imagen/upload', body)
  },
}
