import Vue from 'vue'
// import VueSocketIO from 'vue-socket.io'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import i18n from './i18n'
import './plugins/base'
import './plugins/chartist'
// import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import router from './router'
// import SocketIO from './socket'
import store from './store'

import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'

// const socket = io('http://localhost:3001', {
//   withCredentials: true,
//   transports: ['websocket', 'polling', 'flashsocket'],
// })

const socket = io(process.env.VUE_APP_SOCKET_URL, {
  withCredentials: true,
  transports: ['websocket', 'polling', 'flashsocket'],
})

Vue.config.productionTip = false
Vue.use(VueSocketIOExt, socket, { store })
Vue.use(Vuelidate)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
