/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de boletos de ferry
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló <rbsistemas@hotmail.com>
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */

//import FerryTicket from '@/components/ventas/catalogos/boletos/ferryTicket.service'
import FerryTicket from './ferryTicket.service'
export const namespaced = true

export const actions = {
  /**
   * @name fetchFerryTicket
   * @description Obtiene una lista de Boletos de Ferry
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchFerryTicket(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return FerryTicket.getFerryTickets(params)
  },

  /**
   * @name addFerryTicket
   * @description Crea un nuevo Boleto de ferry
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo Boleto de ferry
   */
  addFerryTicket(context, payload) {
    return FerryTicket.postFerryTicket(payload.body)
  },

  /**
   * @name editFerryTicket
   * @description Edita un nuevo Boleto de ferry
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un Boleto de ferry
   */
  editFerryTicket(context, payload) {
    return FerryTicket.patchFerryTicket(payload.body, payload.id)
  },

  /**
   * @name deleteFerryTicket
   * @description Elimina un pais
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un Pais
   */
  deleteFerryTicket(context, payload) {
    return FerryTicket.deleteFerryTicket(payload.id)
  },
}
