/**
 * @fileoverview Contiene aciones, estados, getters, modulos y mutaciones
 * del store de modelos de idiomas
 *
 * @version 1.0
 *
 * @author José Antonio Rodríguez Barceló
 *
 * @copyright Shuttle Express México
 *
 * Historial
 * ---------
 * La primera versión fue escrita por José Antonio Rodríguez Barceló
 */
import ServicesService from './ServicesServices'
export const namespaced = true

export const actions = {
  /**
   * @name fetchLanguajes
   * @description Obtiene una lista de servicios
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchServices(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ServicesService.getService(params)
  },

  /**
   * @name fetchServicesCategorys
   * @description Obtiene una lista de categoria de servicios
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para poblar el select
   */
  fetchServicesCategorys(context, payload) {
    let params = 'params' in payload ? payload.params : null
    return ServicesService.getServiceCategory(params)
  },

  /**
   * @name addService
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para crear un nuevo servicio
   */
  addService(context, payload) {
    return ServicesService.postService(payload.body)
  },

  /**
   * @name editService
   * @description Edita un nuevo servicio
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para editar un servicio existente
   */
  editService(context, payload) {
    return ServicesService.patchService(payload.body, payload.id)
  },

  /**
   * @name deleteImageService
   *
   * @param {*} context Para hacer referencia al objeto en el que se ejecuta la función
   * @param {*} payload Parametros para eliminar un servicio
   */
  deleteImageService(context, payload) {
    return ServicesService.deleteImageService(payload.body, payload.id)
  },
}
